import React, {useEffect, useState} from 'react';
import Navbar from "../Navbar/Navbar";
import {useParams} from "react-router-dom";
import {ProductsAPI} from "../../api/products";
import {AiOutlineWhatsApp} from "react-icons/ai";
import Categories from "../Categories/Categories";
import {ConsoleLogo} from "../ConsoleLogo/ConsoleLogo";
import {capitalizeFirstLetter, mobileCheck} from "../../utils";
import Loader from "../Loader/Loader";
import { trackGoogleAnalyticsEvent } from '../../App';
import { SinpeModal } from '../Modals/SinpeModal';

const ProductDetail = (props) => {
    const [product, setProduct] = useState();
    const api = new ProductsAPI();
    const productName = product && capitalizeFirstLetter(product?.name);
    const [showSinpeModal, setShowSinpeModal] = useState(false);

    useEffect(() => {

    }, []);


    let { productId } = useParams();

    const whatsappLink = () => {
        trackGoogleAnalyticsEvent("user_action", `buy_product:whatsapp:${product.name}:${productId}`)
        let baseLink = `https://wa.me/50672375342?text=Me Interesa comprar el ${product.name} para ${product.console.console}`
        window.open(baseLink, '_blank').focus();
    }

    useEffect(() => {
        if(!product) {
            api.getProduct(productId).then((data) => {
                setTimeout(() => {
                    setProduct(data)
                    trackGoogleAnalyticsEvent("user_action", `get_product:${data.name}:${productId}`)
                }, 1000)
            });
        }
    }, [product]);

    if(!product) return <Loader></Loader>

    return (
        <div className="w-full flex flex-col items-center md:items-stretch">
            <Navbar />
            <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50" role="alert">
                <svg class="shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="sr-only">Info</span>
                <div>
                    <span class="font-medium">Advertencia!</span> Las imágenes mostradas son ilustrativas y pueden no representar con exactitud el estado o la versión del producto. Para más detalles, contáctanos antes de realizar tu compra.
                </div>
            </div>
            <div className="flex flex-col md:flex-row h-full justify-center">
                <div className="h-full flex-col p-2 pr-6 animate__animated animate__fadeInDown animate__slow">
                    <img className="w-72 md:w-96" src={product.image} alt={product.name}/>
                </div>
                <div className="h-full flex-col p-2">
                    <div className="text-neutral-500 font-bold">{product.barcode}</div>
                    <div className="flex flex-row font-bold text-2xl text-stone-700">
                        {mobileCheck() ?
                            <div className="pt-2.5 text-lg">{productName}</div> :
                            <><div className="pt-2.5 text-lg">{productName} - </div>
                                <ConsoleLogo console={product.console.console} consoleCode={product.console["console-code"]}></ConsoleLogo></>
                        }

                    </div>
                    <div className="font-bold text-3xl pt-4 text-stone-700">₡{product.price.toLocaleString()}</div>
                    <div className="flex flex-row mt-4">
                        <div className={"m-2 p-1 rounded text-white " + (product.used ? "bg-amber-400": "bg-blue-800") }>{product.used ? "usado": "nuevo"}</div>
                        {product.tags.map(
                            (tag) => !tag.internal && <div style={{backgroundColor: tag.color}} className="m-2 p-1 rounded text-white">{tag.name.toLowerCase()}</div>
                        )}
                    </div>
                    <div className='flex flex-col'>
                        <button onClick={() => whatsappLink(productName)}
                            className="mt-12 md:mt-12 bg-sky-700 animate__animated animate__pulse animate__repeat-3
                             text-white rounded px-12 py-3 outline-blue-700">Comprar por Whatsapp <AiOutlineWhatsApp className="inline" /></button>
                        <button onClick={() => setShowSinpeModal(true)}
                            className="mt-12 md:mt-4 bg-sky-700 animate__animated animate__pulse animate__repeat-3
                             text-white rounded px-12 py-3 outline-blue-700">Comprar por SINPE Móvil <img src='/images/SINPE-Steps/logo.png' className='w-[3rem] inline'/></button>
                    </div>
                </div>
            </div>
            <div id="product-description">
                <div className="font-bold underline text-center text-stone-700">Descripción</div>
                <div className="text-center text-stone-700">{product.description}</div>
            </div>
            <Categories></Categories>
            {showSinpeModal && <SinpeModal setShowSinpeModal={setShowSinpeModal} prize={product.price.toLocaleString()} />}
        </div>

    );
};
export default ProductDetail;
